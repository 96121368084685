@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins-fallback";
    size-adjust: 112.5%;
    src: local("Arial");
    font-display: swap;
}

html,
div {
    --scrollbarBG: #cfd8dc;
    --thumbBG: #7d7d7d;
}

html::-webkit-scrollbar:vertical,
div::-webkit-scrollbar:vertical {
    width: 12px;
}

/* html, div {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
} */

html::-webkit-scrollbar-track:vertical,
div::-webkit-scrollbar-track:vertical {
    background: var(--scrollbarBG);
}

div::-webkit-scrollbar-thumb:vertical,
html::-webkit-scrollbar-thumb:vertical {
    /*background-color: var(--thumbBG) ;*/
    border-radius: 20px;
    border: 1px solid var(--scrollbarBG);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]:focus-visible {
    -webkit-appearance: none;
    outline-color: #20C1E7;
}